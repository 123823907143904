// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cgu-js": () => import("./../../../src/pages/cgu.js" /* webpackChunkName: "component---src-pages-cgu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscription-js": () => import("./../../../src/pages/inscription.js" /* webpackChunkName: "component---src-pages-inscription-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-paiement-stripe-js": () => import("./../../../src/pages/PaiementStripe.js" /* webpackChunkName: "component---src-pages-paiement-stripe-js" */),
  "component---src-pages-partenaire-js": () => import("./../../../src/pages/Partenaire.js" /* webpackChunkName: "component---src-pages-partenaire-js" */),
  "component---src-pages-personne-politiquement-exposee-js": () => import("./../../../src/pages/personne-politiquement-exposee.js" /* webpackChunkName: "component---src-pages-personne-politiquement-exposee-js" */),
  "component---src-pages-politique-donnees-personnelles-js": () => import("./../../../src/pages/politique-donnees-personnelles.js" /* webpackChunkName: "component---src-pages-politique-donnees-personnelles-js" */),
  "component---src-pages-qui-sommes-nous-js": () => import("./../../../src/pages/qui-sommes-nous.js" /* webpackChunkName: "component---src-pages-qui-sommes-nous-js" */),
  "component---src-pages-reclamation-js": () => import("./../../../src/pages/reclamation.js" /* webpackChunkName: "component---src-pages-reclamation-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

